import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import AccessControl from "../functional/AccessControl";
import CreateRecordButton from "../input/CreateRecordButton";
import UpdateRecordButton from "../input/UpdateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import getSchema from "../../lib/api/getSchema";
import notificationState from "../../states/notificationState";
import { Tooltip, toggleClass, } from '@grapecity/wijmo';
import "./gridEvent.css"
import DataAllSaveToExcelButton from "../input/DataAllSaveToExcelButton";

function MomSysUdtCompany ( props ) {
    const {id, masterGridSetting} = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);
    const { t } = useTranslation();

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysUdtCompany");
            if(mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();

        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        let tooltip = new Tooltip();
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        masterGridSetting(grid.selectedItems[0]);
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        masterGridSetting([]);
                        setSelectGrid(undefined);
                    };
                });

                grid.formatItem.addHandler((flex, element) => {
                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let data = item.description ? item.description : null;
                        toggleClass(element.cell, 'wj-has-notes', binding === "description" && data !== null );
                        if(binding === "description" && data !== null) {
                            tooltip.setTooltip(element.cell, `<h1>${data}<h1>`);
                        }
                    }
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <>
            <BasicCrudScreenType 
                id={id}
                table="MomSysUdtCompany" 
                view="MomSysVwCompany"
                headerTitle={t("term.company")}
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized} 
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                            <CreateRecordButton
                                id={id}
                                schema={schema}
                                refresh={api}
                                onSuccess={onSuccess}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                            <UpdateRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                onSuccess={onSuccess}
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                            <DeleteRecordButton
                                id={id}
                                schema={schema}
                                grid={grid}
                                refresh={api}
                                color="secondary"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                onPartialFailure={onNotifyWarning}
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>

                        <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL">
                            <DataAllSaveToExcelButton
                                id={id}
                                grid={grid}
                                view="MomSysVwCompany"
                                excelFileName="거래처 정보"
                                color="primary"
                                disabled={selectGrid ? false : true}
                            />
                        </AccessControl>
                    </>
                    )}
                />
        </>
    );

}

export default MomSysUdtCompany;
