import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getDefaultDateFormat, getDefaultLocale } from "../../lib/utils/dateUtil";

function DateRangeSelector ( props ) {

    const { t } = useTranslation ();

    const { hide, onChange } = props;

    const now = new Date ();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const [ all, setAll ] = useState ( false );
    const [ from, setFrom ] = useState (firstDay);
    const [ to, setTo ] = useState (lastDay);

    useEffect ( () => {

        if ( onChange ) {

            if ( from && to ) {

                onChange ( from, to );

            } else {

                onChange ( null, null );

            }

        }

    }, [ onChange, from, to ] );

    const onChangeAll = ( checked ) => {

        setAll ( checked );

        if ( checked ) {

            setFrom ( null );
            setTo ( null );

        } else {

            let now = new Date ();
            let firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
            let lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);


            setFrom ( firstDay );
            setTo ( lastDay );
    
        }

    }

    const onFromChange = ( from ) => {

        from?.setHours ( 0, 0, 0, 0 );
        setFrom ( from );
        setTo ( ( to ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return from;

            }

            return to;

        })

    }

    const onToChange = ( to ) => {
        
        to?.setHours ( 0, 0, 0, 0 );
        setTo ( to );
        setFrom ( ( from ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return to;

            }

            return from;

        })

    }

    return (

        <div style={{marginTop: "8px"}}>

            {
                hide
                ?
                <></>
                : 

                    <FormControlLabel
                    control={
                        <Checkbox
                            checked={ all }
                            color="primary"
                            name={ "all" }
                            onChange={
                                ( e ) => onChangeAll ( e.target.checked )
                            }
                        />
                    }
                    label={ t ( "term.all" ) }
                    style={{ marginLeft: "2px", marginTop: "2px" }}
                />        
            }

            <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        disableToolbar
                        disabled={all}
                        format={getDefaultDateFormat(t, null)}
                        margin="normal"
                        style={{marginRight: "16px", marginTop: "5px", width: "140px"}}
                        value={from}
                        onChange={onFromChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

            <span>~</span>
            <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        disableToolbar
                        disabled={all}
                        format={getDefaultDateFormat(t, null)}
                        margin="normal"
                        style={{marginLeft: "16px", marginTop: "5px", width: "140px"}}
                        value={to}
                        onChange={onToChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

        </div>

    );

}

export default  DateRangeSelector;

