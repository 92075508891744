import * as dateFnsLocales from "date-fns/locale";

export const getDefaultLocale = (t) => dateFnsLocales[t("date.localeName")] ?? dateFnsLocales["ko"];

export const getDefaultDateFormat = (t, format) =>{
    if (dateFnsLocales[t("date.localeName")]) {
    return format ?? t("date.dateFormat");
  }

  return "yyyy/MM/dd";
};

export const getDefaultDateTimeFormat = (t, format) =>{
    if (dateFnsLocales[t("date.localeName")]) {
    return format ?? t("date.dateTimeFormat");
  }

  return "yyyy/MM/dd HH:mm";
};

export const getDefaultTimeFormat = (t, format) =>{
    if (dateFnsLocales[t("date.localeName")]) {
    return format ?? t("date.timeFormat");
  }

  return "HH:mm";
};

export const getDefaultMonthFormat = (t, format) =>{
    if (dateFnsLocales[t("date.localeName")]) {
    return format ?? t("date.monthFormat");
  }

  return "yyyy-MM";
};

  