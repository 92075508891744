import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from '@date-io/date-fns';
import { Button, FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getDefaultDateFormat, getDefaultLocale } from "../../lib/utils/dateUtil";

function DateRangeSelectorWithButtons ( props ) {

    const { t } = useTranslation ();

    const { disableCheckAll, maxRange, startDate, endDate, style, yesterday, prevWeek, prevMonth, prevYear, today, currentWeek, currentMonth, currentYear, onChange } = props;

    const now = new Date ();
    const firstDay = startDate ?? new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = endDate ?? new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const [ all, setAll ] = useState ( false );
    const [ from, setFrom ] = useState (firstDay);
    const [ to, setTo ] = useState (lastDay);

    const onChangeAll = ( checked ) => {

        setAll ( checked );

        if ( checked ) {

            setFrom ( null );
            setTo ( null );

            if ( onChange ) {

                onChange ( null, null );
    
            }

        } else {

            let now = new Date ();
            let firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            let lastDay = new Date(now.getFullYear(), now.getMonth(), 0);


            setFrom ( firstDay );
            setTo ( lastDay );

            if ( onChange ) {

                onChange ( firstDay, lastDay );
    
            }
    
        }

    }

    const onFromChange = ( from ) => {

        from?.setHours ( 0, 0, 0, 0 );
        setFrom ( from );
        setTo ( ( to ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return from;

            }

            if ( maxRange ) {

                let diff = to - from;
                let diffDay = diff / 1000 / 60 / 60 / 24;
    
                if ( diffDay > maxRange ) {
    
                    to = new Date ( from );
                    to.setDate ( from.getDate () + maxRange );
                    
                }
    
            }

            if ( onChange ) {

                onChange ( from, to );

            }

            return to;

        })

    }

    const onToChange = ( to ) => {
        
        to?.setHours ( 0, 0, 0, 0 );
        setTo ( to );
        setFrom ( ( from ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return to;

            }

            if ( maxRange ) {

                let diff = to - from;
                let diffDay = diff / 1000 / 60 / 60 / 24;
    
                if ( diffDay > maxRange ) {
    
                    from = new Date ( to );
                    from.setDate ( to.getDate () - maxRange );
    
                }
    
            }

            if ( onChange ) {

                onChange ( from, to );

            }

            return from;

        })

    }

    const onYesterdayClick = () => {

        let now = new Date ();
        let start = new Date ( now );

        start.setDate ( now.getDate () - 1 );

        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        let end = new Date( start );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {

            onChange ( start, end );

        }

    };

    const onPrevWeekClick = () => {

        let now = new Date ();
        let start = new Date ( now );

        let day = now.getDay ();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }
        start.setDate ( now.getDate () - 7 - day );

        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        let end = new Date( start );

        end.setDate ( start.getDate () + 6 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {

            onChange ( start, end );

        }

    };

    const onPrevMonthClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setMonth ( now.getMonth () - 1 );
        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setMonth ( now.getMonth () );
        end.setDate ( 0 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {
            
            onChange ( start, end );

        }

    };

    const onPrevYearClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setYear ( now.getFullYear () - 1 );
        start.setMonth ( 0 );
        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setYear ( now.getFullYear () - 1 );
        end.setMonth ( 11 );
        end.setDate ( 31 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {
            
            onChange ( start, end );

        }

    };

    const onTodayClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date( start );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {

            onChange ( start, end );

        }

    };

    const onCurrentWeekClick = () => {

        let now = new Date ();
        let start = new Date ( now );

        let day = now.getDay ();

        if ( day === 0 ) {

            day = 6;

        } else {

            day--;

        }

        start.setDate ( now.getDate () - day );

        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        let end = new Date( start );

        end.setDate ( start.getDate () + 6 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {

            onChange ( start, end );

        }

    };

    const onCurrentMonthClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setMonth ( now.getMonth () + 1 );
        end.setDate ( 0 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {
            
            onChange ( start, end );

        }

    };

    const onCurrentYearClick = () => {

        let now = new Date ();
        let start = new Date ( now );
        let end = new Date ( now );

        start.setMonth ( 0 );
        start.setDate ( 1 );
        start.setHours ( 0 );
        start.setMinutes ( 0 );
        start.setSeconds ( 0 );
        start.setMilliseconds ( 0 );

        end.setYear ( now.getFullYear () );
        end.setMonth ( 11 );
        end.setDate ( 31 );
        end.setHours ( 23 );
        end.setMinutes ( 59 );
        end.setSeconds ( 59 );
        end.setMilliseconds ( 999 );

        setFrom ( start );
        setTo ( end );

        if ( onChange ) {
            
            onChange ( start, end );

        }

    };
    
    return (

        <div style={style??{marginTop: "8px"}}>

            {
                disableCheckAll
                ?
                <></>
                :
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={ all }
                            color="primary"
                            name={ "all" }
                            onChange={
                                ( e ) => onChangeAll ( e.target.checked )
                            }
                        />
                    }
                    label={ t ( "term.all" ) }
                    style={{ marginLeft: "2px", marginTop: "2px" }}
                />        
            }

            <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        disableToolbar
                        disabled={all}
                        format={getDefaultDateFormat(t, null)}
                        margin="normal"
                        style={{marginRight: "16px", marginTop: "5px", width: "140px"}}
                        value={from}
                        onChange={onFromChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

            <span>~</span>
            <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        disableToolbar
                        disabled={all}
                        format={getDefaultDateFormat(t, null)}
                        margin="normal"
                        style={{marginLeft: "16px", marginTop: "5px", width: "140px"}}
                        value={to}
                        onChange={onToChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

            {
                yesterday
                ?
                <Button disabled={all} onClick={onYesterdayClick}>{t("term.yesterday")}</Button>
                :
                <></>
            }
            {
                prevWeek
                ?
                <Button disabled={all} onClick={onPrevWeekClick}>{t("term.prevWeek")}</Button>
                :
                <></>
            }
            {
                prevMonth
                ?
                <Button disabled={all} onClick={onPrevMonthClick}>{t("term.prevMonth")}</Button>
                :
                <></>
            }
            {
                prevYear
                ?
                <Button disabled={all} onClick={onPrevYearClick}>{t("term.prevYear")}</Button>
                :
                <></>
            }
            {
                today
                ?
                <Button disabled={all} onClick={onTodayClick}>{t("term.today")}</Button>
                :
                <></>
            }
            {
                currentWeek
                ?
                <Button disabled={all} onClick={onCurrentWeekClick}>{t("term.currentWeek")}</Button>
                :
                <></>
            }
            {
                currentMonth
                ?
                <Button disabled={all} onClick={onCurrentMonthClick}>{t("term.currentMonth")}</Button>
                :
                <></>
            }
            {
                currentYear
                ?
                <Button disabled={all} onClick={onCurrentYearClick}>{t("term.currentYear")}</Button>
                :
                <></>
            }

        </div>

    );

}

export default  DateRangeSelectorWithButtons;

