
/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import getSchema from "../../lib/api/getSchema";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";
import { CellType } from '@grapecity/wijmo.grid';
import AccessControl from "../functional/AccessControl";
import PopCrudScreenActionItemLayout from "../screenTypes/layout/PopCrudScreenActionItemLayout";
import notificationState from "../../states/notificationState";
import { useSetRecoilState } from "recoil";
import SaveToExcelButton from "../input/SaveToExcelButton";
import EguPopProductManageDetail from "./EguPopProductManageDetail";
import EguPopInputMaterialButton from "../input/EguPopInputMaterialButton";
import EguPopProduceStartButton from "../input/EguPopProduceStartButton";
import EguPopProduceEndButton from "../input/EguPopProduceEndButton";
import EguPopHoldAndReleaseButton from "../input/EguPopHoldAndReleaseButton";
import EguPopQCRequestButton from "../input/EguPopQCRequestButton";
import EguPopOutputMaterialButton from "../input/EguPopOutputMaterialButton";
import getSpecific from "../../lib/api/getSpecific";
import { produceStatus } from "../../config/defineConf";
import defineConf from "../../config/defineConf";
import apiClient from "../../lib/common/apiClient";
import CreateRecordButtonLayout from "../layout/CreateRecordButtonLayout";
import EguPopDateRangeSelector from "../input/EguPopDateRangeSelector";
import MomSysPopNoticeDisplay from "./MomSysPopNoticeDisplay";
import {debounce} from "lodash";
import { Tooltip, toggleClass, PopupPosition } from '@grapecity/wijmo';
import PrintReportButton from "../input/PrintReportButton";

function EguPopProductManage(props) {

    const {
        id,
        name,
    } = props;

    const table = "EguPopProductManage";

    const view = "EguVwPopProductManage";

    const [grid, setGrid] = useState();
    const [, setDetailApi] = useState();

    const [onSelectMst, setOnSelectMst] = useState({});

    const [height, setHeight] = useState(0);

    const { t } = useTranslation();

    const [schema, setSchema] = useState();

    const [api, setApi] = useState();

    const [data, setData] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const [, setApprovalCtx] = useState();

    const [queries, setQueries] = useState({ $button: null });

    const [trigger, setTrigger] = useState(false);

    const [, setReportUrl] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const handleResize = () => {

        setHeight(document.documentElement.offsetHeight - 144);

    };

    useEffect(() => {


        if (api) {

            api.refresh();

        }


    }, [id]);

    useEffect (() => {

        let url =
            ( process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging')
                ?
                `/report/EguVwPopProductManage/${onSelectMst.planId}/${t("term.LotInfo")})}`
                :
                `http://localhost:8080/report/EguVwPopProductManage/${onSelectMst.planId}/${t("term.LotInfo")}`;

        setReportUrl(url);

    }, [onSelectMst]);

    useEffect(() => {
        let id;
        if (!trigger) {

            id = setInterval(() => {
                if (api) {

                    api.refresh();

                }

            }, 3 * 100000);
        } else {

            clearInterval(id)
        }
        return () => {
            clearInterval(id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, api])

    const onTrigger = (trigger) => {
        setTrigger(trigger)
    }

    useEffect(() => {

        let mounted = true;

        (async () => {

            let schema = await getSchema(table);

            if (mounted) {
                setSchema(schema);
            }

        })();

        window.addEventListener('resize', handleResize);

        handleResize();

        return (() => {
            mounted = false;
            window.removeEventListener('resize', handleResize);
        });

    }, []);

    useEffect(() => {

        let approvalCtx = {

            approvalTypeId: 15,
            tableName: table,
            statusColumn: "approvalStateId",
            schema: schema,
            onValidateApprovalItem: (item) => {

                return [true, ""];

            },
            makeRequestItemDescription: (item) => {

                return `${t("approvalRequestMsg.msg.popProcess", { processName: item.processName, orderBuyerId: item.orderBuyerId, materialName: item.materialName })}`; // Use Translation on Production Code

            }

        };

        setApprovalCtx(approvalCtx);

    }, [schema]);

    useEffect(() => {

        let tooltip = new Tooltip({});

        (async () => {
            
            if (grid) {

                grid.selectionChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([])

                    }

                });

                grid.itemsSourceChanged.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {

                        setOnSelectMst(grid.selectedItems[0])

                    } else {

                        setOnSelectMst([])

                    }

                });

                grid.refreshed.addHandler((grid, event) => {

                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setOnSelectMst((pre) => { // 참조 변경
                            let newData = {
                                ...pre,
                                ...grid.selectedItems[0]
                            }
                            return newData
                        })

                    } else {

                        setOnSelectMst([])

                    }

                });

                grid.formatItem.addHandler( (flex, element) => {

                    if (element.panel === flex.cells) {
                        let item = flex.rows[element.row].dataItem
                        let binding = flex.columns[element.col].binding
                        let resultLotNoData = item.resultLotNo ? item.resultLotNo : null;
                        // let extLotNoData = item.extLotNo ? item.extLotNo : null;

                            switch(binding) {
                                case "resultLotNo" : 
                                    toggleClass(element.cell, 'wj-has-notes', binding === "resultLotNo" && resultLotNoData !== null );
                                break;
    
                                // case "extLotNo" : 
                                //     toggleClass(element.cell, 'wj-has-notes', binding === "extLotNo" && extLotNoData !== null );
                                // break;
    
                                default :
                                break;
                            }
                    }

                });

                grid.addEventListener(grid.hostElement, 'mouseover', debounce(e => {

                    if(e.target.classList.contains("wj-has-notes")) {

                        (async()=>{

                            let result = ((await apiClient.get(`api/MomSysGetLastProcessSubdivisionByLotNo/${e.target.innerText}`))).data.result[0];

                            let tooltipText = '';

                            for(let lotInfo of result) {

                                tooltipText += `<span style = "font-size = 7px"/><b>lotNo</b> : ${lotInfo.lotNo} <b>현재 수량 :</b> ${lotInfo.qtyCurrent} <br> <b>extLotNo</b> : ${lotInfo.extLotNo }</span> <br>`;

                            }
                            
                            tooltip.show(e.target, tooltipText, undefined, PopupPosition.Right )
                        })();
                    }
                },200));
                grid.addEventListener(grid.hostElement, 'mouseout', e => {

                    if(e.target.classList.contains("wj-has-notes")) {
                        if(tooltip.isVisible) {
                            tooltip.hide();
                        }
                    }
                });
            }

        })()

    }, [grid]);

    const onLoadData = (data) => {

        setData(data);

        setIsEmpty(data.length < 1 ? true : false)

    };

    const onInitialized = grid => {

        grid.selectionMode = "Row";

        setGrid(grid);

    };

    const detailApiInitialized = api => {

        setDetailApi(api);

    };

    const onApiInitialized = (api) => {

        setApi(api);

    };

    const onUpdateMaster = () => {

        setDetailApi((api) => {

            if (api) {

                api.refresh();

            }

            return api;

        })

        setGrid((grid) => {

            if (grid) {

                (async () => {

                    let ProductMst = await getSpecific("EguVwPopProductManage", grid.selectedItems[0].planId);

                    if (ProductMst) {

                        for (let x in grid?.selectedItems[0]) {
                            grid.selectedItems[0][x] = ProductMst[x]
                        }
                    }
                    setOnSelectMst(grid.selectedItems[0]);
                    grid.refresh(false);

                })();

            }

            return grid;

        })

    }

    const exportFormatItem = (args) => {

        let { panel, col, row, xlsxCell } = args;

        if (panel.cellType === CellType.Cell) {

            switch (panel.columns[col].binding) {

                case "approvalState":

                    switch (data[row][panel.columns[col].binding]) {

                        case 1:

                            xlsxCell.value = t("selectItems.approvalState.approvalPending")

                            break;

                        case 2:

                            xlsxCell.value = t("selectItems.approvalState.partiallyApproved")

                            break;

                        case 3:

                            xlsxCell.value = t("selectItems.approvalState.totallyApproved")

                            break;

                        case 4:

                            xlsxCell.value = t("selectItems.approvalState.approvalRejected")

                            break;

                        case 5:

                            xlsxCell.value = t("selectItems.approvalState.approvalWithdrawn")

                            break;

                        case 6:

                            xlsxCell.value = t("selectItems.approvalState.approvalCancelled")

                            break;

                        default:
                            break;
                    }

                    break;

                default:

                    break;

            }

        }

    };

    const notifySuccess = (msg) => {

        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });

    };

    const onSuccess = (msg) => {

        notifySuccess(msg);

        if (onUpdateMaster) {
            onUpdateMaster();
        }
        // setApi((api) => {

        //     if (api) {

        //         api.refresh();

        //     }

        //     return api;

        // });

    };

    const onSuccessRefresh = (msg) => {

        notifySuccess(msg);

        if (api) {

            api.refresh();

        }

        setDetailApi((api) => {

            if (api) {

                api.refresh();

            }

            return api;

        })
    };


    const onDetailSuccess = (msg) => {

        notifySuccess(msg);

    };

    const notifyFailure = (msg) => {

        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });

    };

    const onFailure = (reason) => {

        notifyFailure(reason.response ? reason.response.data : reason);

    };

    const doHandleBackendErrorMsg = (reason) => {

        // reason.response := { data, status, statusText, headers, config, request }
        // data : user error message
        // status : http status code
        // statusText : http status code text
        const response = reason.response;
        return `${response.data} `;

    };

    const onModifyViewUrl = (url, id, name) => {

        return url + "/category/" + id + "/" + name;

    };

    const onButtonFilter = (param) => {

        let condition = null;

        if (param !== queries.$button) condition = param;

        setQueries({ $button: condition });

    }

    const makeQueries = (from, to) => {

        let queries = {
            $from: from,
            $to: to,
        };

        return queries;

    };

    const onDateRangeChanged = (from, to) => {

        let queris = makeQueries(from, to);
        setQueries(queris);
    };

    const onAppendButtonAtLast = () => {
        if (id === "WorkRecords") return (
            <>
                <EguPopDateRangeSelector onChange={onDateRangeChanged} checked={true} />
            </>
        );
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    <CreateRecordButtonLayout
                        id={id + "-today-button"}
                        createButtonName={"todayWork"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.TODAY)}
                        color={queries.$button === defineConf.buttonFilterIndex.TODAY ? "secondary" : "primary"}
                    />
                    <CreateRecordButtonLayout
                        id={id + "-tomorrow-button"}
                        createButtonName={"tomorrowWork"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.TOMORROW)}
                        color={queries.$button === defineConf.buttonFilterIndex.TOMORROW ? "secondary" : "primary"}
                    />
                    <CreateRecordButtonLayout
                        id={id + "-weekly-button"}
                        createButtonName={"weekly"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.WEEKLY)}
                        color={queries.$button === defineConf.buttonFilterIndex.WEEKLY ? "secondary" : "primary"}
                    />
                    <CreateRecordButtonLayout
                        id={id + "-unworked-button"}
                        createButtonName={"unworked"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.UNWORKED)}
                        color={queries.$button === defineConf.buttonFilterIndex.UNWORKED ? "secondary" : "primary"}
                    />
                    <CreateRecordButtonLayout
                        id={id + "-working-button"}
                        createButtonName={"working"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.WORKING)}
                        color={queries.$button === defineConf.buttonFilterIndex.WORKING ? "secondary" : "primary"}
                    />
                    <CreateRecordButtonLayout
                        id={id + "-popAll-button"}
                        createButtonName={"popAll"}
                        onClick={() => onButtonFilter(defineConf.buttonFilterIndex.POPALL)}
                        color={queries.$button === defineConf.buttonFilterIndex.POPALL ? "secondary" : "primary"}
                    />
                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    const onAppendButtonInFrontOfSearch = () => {
        if (id === "WorkRecords") return <></>;
        // if (id === "PopAll") return (
        //     <>
        //         <PopCrudScreenActionItemLayout>
        //             {/* 자재반납 */}
        //             <EguPopOutputMaterialButton
        //                 id={table}
        //                 onSelectMst={onSelectMst}
        //                 schema={schema}
        //                 onSuccess={onSuccess}
        //                 onFailure={onFailure}
        //                 onTrigger={onTrigger}
        //                 createButtonName={"outputMaterial"}
        //                 disabled={!(parseInt(onSelectMst?.produceStatus) === produceStatus.COMPLETION) || isEmpty}
        //                 customizeErrorMsgCallback={doHandleBackendErrorMsg}
        //             />
        //         </PopCrudScreenActionItemLayout>
        //     </>
        // );
        return (
            <>
                <PopCrudScreenActionItemLayout>
                    {/* 작업시작 */}
                    <EguPopProduceStartButton
                        id={table}
                        onSelectMst={onSelectMst}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onTrigger={onTrigger}
                        disabled={!(parseInt(onSelectMst?.produceStatus) === produceStatus.UNWORKED) || isEmpty}
                    />
                    {/* 작업보류 */}
                    <EguPopHoldAndReleaseButton
                        id={table}
                        onSelectMst={onSelectMst}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        createButtonName={"holdProcessing"}
                        onTrigger={onTrigger}
                        disabled={!(parseInt(onSelectMst?.produceStatus) === produceStatus.WORKING) || isEmpty}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                    {/* 작업재개 */}
                    <EguPopHoldAndReleaseButton
                        id={table}
                        onSelectMst={onSelectMst}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        createButtonName={"releaseHoldProcessing"}
                        onTrigger={onTrigger}
                        disabled={!(parseInt(onSelectMst?.produceStatus) === produceStatus.HOLDING) || isEmpty}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                    {/* 작업종료 */}
                    <EguPopProduceEndButton
                        id={table}
                        onSelectMst={onSelectMst}
                        onSuccess={onSuccessRefresh}
                        onFailure={onFailure}
                        onTrigger={onTrigger}
                        disabled={([produceStatus.UNWORKED, produceStatus.HOLDING, produceStatus.COMPLETION, produceStatus.OUTPUT]).includes(parseInt(onSelectMst?.produceStatus)) || isEmpty}

                    />
                    {/* 자재인수 */}
                    <EguPopInputMaterialButton
                        id={table}
                        onSelectMst={onSelectMst}
                        schema={schema}
                        onSuccess={onDetailSuccess}
                        onUpdateMaster={onUpdateMaster}
                        onTrigger={onTrigger}
                        disabled={([produceStatus.COMPLETION, produceStatus.OUTPUT]).includes(parseInt(onSelectMst?.produceStatus)) || isEmpty}
                    />
                    {/* 자재반납 */}
                    <EguPopOutputMaterialButton
                        id={table}
                        onSelectMst={onSelectMst}
                        schema={schema}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onTrigger={onTrigger}
                        createButtonName={"outputMaterial"}
                        disabled={!(parseInt(onSelectMst?.produceStatus) === produceStatus.COMPLETION) || isEmpty}
                        customizeErrorMsgCallback={doHandleBackendErrorMsg}
                    />
                    {/* QC 의뢰 */}
                    <EguPopQCRequestButton
                        id={table}
                        onSelectMst={onSelectMst}
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        onTrigger={onTrigger}
                        createButtonName={"QCRequest"}
                        disabled={parseInt(onSelectMst?.produceStatus) !== produceStatus.COMPLETION || isEmpty}
                    />
                    <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL">
                        <SaveToExcelButton
                            id="EguPopProductManageExcel"
                            grid={grid}
                            view={view}
                            color="primary"
                            exportFormatItem={exportFormatItem}
                            disabled={isEmpty}
                        />
                    </AccessControl>

                    <PrintReportButton
                        id="EguVwPopProductManageReport"
                        noLayout
                        color="primary"
                        disabled={!(onSelectMst?.expirationDate && onSelectMst?.manufacturedDate)}
                        title={t("term.LotInfo")}
                        reportName="EguVwPopProductManage"
                        reportData={{

                            title: t("term.LotInfo"),
                            materialCode: onSelectMst?.bopMaterialCode,
                            materialCodeVer: onSelectMst?.bopMaterialCodeVer,
                            materialName: onSelectMst?.materialName,
                            qty: onSelectMst?.resultQty,
                            mediumUnitQty: onSelectMst?.mediumUnitQty,
                            smallUnitQty: onSelectMst?.smallUnitQty,
                            lotNo: onSelectMst?.resultLotNo,
                            extLotNo: onSelectMst?.extLotNo,
                            orderBuyerId: onSelectMst?.orderBuyerId,
                            orderBuyerName: onSelectMst?.orderBuyerName,
            
                        }}
                    >
                        {t("buttonName.lotInfoPrint")}
                    </PrintReportButton>

                </PopCrudScreenActionItemLayout>
            </>
        );
    }

    return (

        <div style={{ height: height }} >

            <ReflexContainer orientation="horizontal">

                <ReflexElement flex={0.65} data-cy="Master">

                    <BasicCrudScreenType
                        id={"pop"}
                        table={table}
                        view={view}
                        onInitialized={onInitialized}
                        onApiInitialized={onApiInitialized}
                        onLoadData={onLoadData}
                        onModifyViewUrl={(url) => onModifyViewUrl(url, id, name)}
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        $queries={queries}
                        onAppendButtonAtLast={onAppendButtonAtLast}
                        onAppendButtonInFrontOfSearch={onAppendButtonInFrontOfSearch}
                    />

                </ReflexElement>

                <ReflexSplitter style={{ height: layoutConf.reflex.splitterHeight }} />

                <ReflexElement flex={0.35}>

                    {
                        (onSelectMst)
                            ?
                            <ReflexContainer orientation="vertical">

                                <ReflexElement flex={0.65} data-cy="Detail">

                                    <EguPopProductManageDetail
                                        id={`${id}DetailByTotal`}
                                        view={`${view}DetailByTotal`}
                                        planId={onSelectMst?.planId}
                                        detailApiInitialized={detailApiInitialized}
                                        titleName={name}
                                    />

                                </ReflexElement>

                                <ReflexSplitter style={{ width: layoutConf.reflex.splitterWidth }} />

                                <ReflexElement flex={0.35}>

                                    <MomSysPopNoticeDisplay orderBuyerDetailId={onSelectMst?.orderBuyerDetailId} processCode={onSelectMst?.processCode} />

                                </ReflexElement>

                            </ReflexContainer>
                            :
                            <></>
                    }

                </ReflexElement>

            </ReflexContainer>

        </div>

    );

}

export default EguPopProductManage;
