import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import DeleteRecordButton from "../input/DeleteRecordButton";
import SaveToExcelButton from "../input/SaveToExcelButton";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";

import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import EguMachineRentalOutCreateButton from "../input/EguMachineRentalOutCreateButton";
import EguMachineRentalOutUpdateButton from "../input/EguMachineRentalOutUpdateButton";
import EguMachineRentalInUpdateButton from "../input/EguMachineRentalInUpdateButton";

function EguMachineRental(props) {
    const { id } = props;

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const { t } = useTranslation();

    useEffect(() => {

        let mounted = true;

        async function schemaSetting() {
            
            let schema = await getSchema("EguMachineRental");

            if ( mounted ) {
                setSchema(schema);
            }

        }
 
        schemaSetting();

        return ( () => {
            mounted = false;
        } );

    }, []);

    useEffect(() => {
        (async() => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                });
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return (
        <>
            <BasicCrudScreenType
                id={id || "EguMachineRental"}
                table="EguMachineRental"
                view="EguVwMachineRental"
                headerTitle={t("term.machineRental")}
                onApiInitialized={onApiInitialized}
                onInitialized={onInitialized}
                noCreate
                noEdit
                noDelete
                noExcel
                onAppendButtonInFrontOfSearch={() => (
                    <>
                        <BasicCrudScreenActionItemLayout>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                                <EguMachineRentalOutCreateButton
                                    id = "EguMachineRentalOutCreate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSuccess = {onSuccess}
                                    createButtonName = "machineRentalOutCreate"
                                    modifyUrlCallback={(url) => `${url}?outInsert`}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                                <EguMachineRentalOutUpdateButton
                                    id = "EguMachineRentalOutUpdate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSuccess = {onSuccess}
                                    grid = {grid}
                                    createButtonName = "machineRentalOutUpdate"
                                    modifyUrlCallback={(url) => `${url}?outUpdate`}
                                    disabled = {selectGrid ? false : true}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch?"PATCH":"PUT")}>
                                <EguMachineRentalInUpdateButton
                                    id = "EguMachineRentalInUpdate"
                                    schema = {schema}
                                    refresh = {api}
                                    onSuccess = {onSuccess}
                                    grid = {grid}
                                    createButtonName = "machineRentalInUpdate"
                                    modifyUrlCallback={(url) => `${url}?inUpdate`}
                                    disabled = {selectGrid ? false : true}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                                <DeleteRecordButton
                                    id={id || "EguMachineRental"}
                                    schema={schema}
                                    grid={grid}
                                    refresh={api}
                                    color="secondary"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    onPartialFailure={onNotifyWarning}
                                    disabled = {selectGrid ? false : true}
                                />
                            </AccessControl>
                            <AccessControl accessTarget={schema && schema.name} accessMethod="SAVETOEXCEL" >
                                <SaveToExcelButton
                                    id={id}
                                    grid={grid}
                                    view="EguVwMachineRental"
                                    color="primary"
                                    disabled = {selectGrid ? false : true}
                                />
                            </AccessControl>
                        </BasicCrudScreenActionItemLayout>
                    </>
                )}
            />
        </>
    );
}

export default EguMachineRental;
