/* eslint-disable react-hooks/exhaustive-deps */
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import defineConf from "../../config/defineConf";

function OrderPurchaseMstTarget(props) {
  const { setOnSelect } = props;

  const { t } = useTranslation();
  const [grid, setGrid] = useState();

  useEffect(() => {
    (async () => {
      if (grid) {
        grid.selectionChanged.addHandler((grid, event) => {
          if (grid.selectedItems && grid.selectedItems[0]) {
            setOnSelect(grid.selectedItems[0]);
          } else {
            setOnSelect([]);
          }
        });

        grid.itemsSourceChanged.addHandler((grid, event) => {
          if (grid.selectedItems && grid.selectedItems[0]) {
            setOnSelect(grid.selectedItems[0]);
          } else {
            setOnSelect([]);
          }
        });
      }
    })();
  }, [grid]);

  const onModifyViewSchema = (schema) => {
    for (let column of schema.columns) {
      switch (column.name) {
        case "orderPurchaseId":
        case "orderBuyerId":
        case "orderPurchaseName":
        case "companyName":
        case "orderPurchaseDate":
        case "dueDate":
        case "cost":
        case "description":
        case "createdAt":
        case "createdByFullName":
          break;

        default:
          column.grid = {
            hide: true,
          }
          break;
      }
    }
  };

  const onInitialized = (grid) => {
    grid.selectionMode = "Row";
    setGrid(grid);
  };

  return (
    <BasicCrudScreenType
      id="OrderPurchaseMst"
      headerTitle={t("term.InputPo")}
      view="EguVwOrderPurchaseMst"
      noCreate
      noEdit
      noDelete
      noExcel
      noFilter
      onInitialized={onInitialized}
      onModifyViewSchema={onModifyViewSchema}
      onModifyViewUrl={(url) =>
        `${url}/inputState/${defineConf.orderPurchaseInputState.COMPLETED} `
      }
    />
  );
}

export default OrderPurchaseMstTarget;
