import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Checkbox, Divider, FormControlLabel, Snackbar } from "@material-ui/core";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogHeader from "../dialog/functional/DialogHeader";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import AutoForm from "../form/functional/AutoForm";
import { debounce } from 'lodash';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px",
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text,
    },
}));

function MomSysMaterialOptionCreateDialog(props) {
    const classes = useStyles();

    const {
        id,
        mode,
        schema,
        refresh,

        title,
        formId,

        moveable,
        resizable,
        fullWidth,

        onInitialized,
        onSuccess,
        onClose,

        onDialogUpdateDetail,

        appendCheckBox,

        labels,
        view,
        headerTitle,
        onModifyViewUrl,
        customValidation,
        apiUrl,
        platform,
        maxWidth,
        method,

        ...others
    } = props;

    const [, setHeight] = useState(0);

    const [dialogInstance, setDialogInstance] = useState();
    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);

    const [grid, setGrid] = useState();

    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });

    const [selectGrid, setSelectGrid] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const { t } = useTranslation();

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                },10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                }),10);
            }
        })();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);

    useEffect(()=>{
        if(selectGrid){
            setFormData((formData)=>{
                let newFormData = {
                    ...formData,
                    materialCode : selectGrid.materialCode,
                    materialCodeVer : selectGrid.materialCodeVer,
                }

                return newFormData;
            })
        }
    },[selectGrid]);
    
    const onCloseDialog = () => {
        if (onClose) {
            onClose();
        }
        setOkButtonDisabled(true)
        setFormData();
    };

    const onCancel = () => {
        dialogInstance.hide();
    };

    const onDialogInitialized = (instance) => {
        setDialogInstance(instance);

        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onChangeFormData = (formData) => {
        setFormData(formData);

        setOkButtonDisabled(false);
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const nofifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onApiSuccess = (result) => {

        if (onSuccess) {
            setTimeout(onSuccess(result.data), 0);
        }
        if (!keepOpen) {
            dialogInstance.hide();
        }

    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("error.insertFailure", {
                reason: reason.response ? reason.response.data : JSON.stringify(reason),
            })
        );
    };

    const checkItemValidation = (schema, formData) => {
        console.log(formData)
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation("create", column, column.validation, formData[column.name] || "");

            if (!valid) {
                nofifyWarning(msg);
                return false;
            }
        }

        return true;
    };

    const onOk = (schema, formData) => {

        if (customValidation) {
            if (customValidation(schema, formData)) {
                console.error(" From Validation Failed ");

                return;
            }
        }

        if (!checkItemValidation(schema, formData)) {
            console.error(" From Validation Failed ");

            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(formData, schema.validation);

            if (!valid) {
                nofifyWarning(t("warning.formDataValidationFail", { msg: msg }));
                return;
            }
        }

        let url = `/api/${schema.name}`;

        if (apiUrl) {
            url = apiUrl;
        }
        const apiData = { ...formData };

        const form = new FormData();

        const json = {};
        const deleted = {};

        for (const formItem in apiData) {
            if (apiData[formItem] !== undefined) {
                json[formItem] = apiData[formItem];
            }
        }

        form.append("deleted", JSON.stringify(deleted));
        form.append("json", JSON.stringify(json));

        switch (method?.toLowerCase()) {
            case "put":
                apiClient
                    .put(url, form, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => onApiSuccess(result))
                    .catch((reason) => onApiFailure(reason));
                break;
            case "patch":
                apiClient
                    .patch(url, form, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => onApiSuccess(result))
                    .catch((reason) => onApiFailure(reason));
                break;
            default:
                apiClient
                    .post(url, form, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((result) => onApiSuccess(result))
                    .catch((reason) => onApiFailure(reason));
                break;
        }

    };

    const onCloseNotification = () => {
        setNotification((notification) => {
            return { ...notification, open: false };
        });
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={() => setKeepOpen((keepOpen) => !keepOpen)}
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />

                {appendCheckBox ? (
                    appendCheckBox()
                ) : (
                    <></>
                )}
                <div style={{ flexGrow: 1 }}></div>
            </>
        );
    };

    return (
        <>
            <Dialog
                id={id}
                platform={platform ? platform : uiConf.dialogPlatform}
                moveable={moveable ? moveable : uiConf.dialogMoveable}
                resizable={resizable ? resizable : uiConf.dialogResizable}
                fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
                maxWidth={"lg"}
                // maxWidth={maxWidth ? maxWidth : uiConf.autoFormDialogMaxWidth}
                onClose={onCloseDialog}
                onInitialized={onDialogInitialized}
                {...others}
            >
                <DialogHeader>
                    <DialogTitle>{title}</DialogTitle>
                </DialogHeader>

                <Divider />

                <DialogContent dividers style={{ padding: "0px", height: "600px" }}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.65}>
                            <BasicCrudScreenType
                                id="MomSysZvwMaterial"
                                embededInOtherScreenType
                                headerTitle={t("tableName.MomSysZvwMaterial")}
                                view="MomSysZvwMaterial"
                                noCreate
                                noEdit
                                noDelete
                                noExcel
                                onInitialized={(grid) => {
                                    setGrid(grid);
                                    grid.selectionMode = "Row";
                                }}
                            />
                        </ReflexElement>

                        <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                        <ReflexElement flex={0.35}>
                            <div
                                style={{
                                    padding: "32px",
                                }}
                            >
                                <AutoForm
                                    id={`${schema?.name}create-form`}
                                    schema={schema}
                                    mode="edit"
                                    initialData={formData}
                                    onChange={onChangeFormData}
                                />
                            </div>
                        </ReflexElement>
                    </ReflexContainer>


                    <Snackbar
                        open={notification.open && notification.severity !== "success"}
                        autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                        onClose={onCloseNotification}
                    >
                        <Alert onClose={onCloseNotification} variant="filled" severity={notification.severity}>
                            {notification.msg}
                        </Alert>
                    </Snackbar>
                </DialogContent>

                <Divider />

                <DialogActionsOkCancel
                    firstActionComponent={checkKeepOpen}
                    labels={labels}
                    buttonDisabled={[okButtonDisabled, false]} /* [okButtonDisabled, cancelButtonDisabled] */
                    onOk={() => onOk(schema, formData)}
                    onCancel={onCancel}
                ></DialogActionsOkCancel>
            </Dialog>
        </>
    );
}

export default MomSysMaterialOptionCreateDialog;
