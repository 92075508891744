import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

import getNewDateObj from "../../../lib/common/getNewDateObj";
import { getDefaultDateFormat, getDefaultLocale } from '../../../lib/utils/dateUtil';

function DateSelectForm ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, InputLabelProps, onChange, value, ...others } = props;

    let dateFieldProps = {
        onChange: ( date ) => {
            if (date && isNaN(date.getTime())) {
                return;
            }
            date?.setHours ( 0, 0, 0, 0 );
            onChange ( date );
            
        },
        ...others
    };

    if ( value !== "" ) {

        dateFieldProps.value = getNewDateObj ( value );

    } else {
            
        dateFieldProps.value = null;
    
    }

    return (
        <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
            <FormControl className={className} style={{width: "180px"}} {...InputLabelProps} {...others}>
                <KeyboardDatePicker
                    variant="inline"
                    disableToolbar
                    strictCompareDates
                    format={getDefaultDateFormat(t, null)}
                    margin="normal"
                    helperText={helperText}
                    {...dateFieldProps}
                    autoOk={true}
                    style={{marginTop: "0px"}}
                />
            </FormControl>
        </MuiPickersUtilsProvider>

    );

}

export default DateSelectForm;
