import { useEffect, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import layoutConf from "../../config/layoutConf";

import IncomingInspectionTarget from './IncomingInspectionTarget';
import InspectionGroup from './InspectionGroup';
import InspectionItem from './InspectionItem';
import InspectionResult from './InspectionResult';
import defineConf from '../../config/defineConf';
import getSpecific from '../../lib/api/getSpecific';

function IncomingInspection ( props ) {
    const [height, setHeight] = useState(0);
    const [selectMstGrid, setSelectMstGrid] = useState();
    const [mstApi, setMstApi] = useState();
    const [selectGroupByMaterialGrid, setSelectGroupByMaterialGrid] = useState();
    const [selectGroupItemGrid, setSelectGroupItemGrid] = useState();
    const [, setTargetGrid] = useState();
    const [, setTargetItemGrid] = useState();

    const handleResize = () => {
        setHeight(document.documentElement.offsetHeight - 96);
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const masterGridSetting = (data) => {
        setSelectMstGrid(data)
    } 

    const masterApiSetting = (api) => {
        setMstApi(api);
    }

    const groupByMaterialGridSetting = (data) => {
        setSelectGroupByMaterialGrid(data);
    }

    const groupItemGridSetting = (data) => {
        setSelectGroupItemGrid(data);
    }

    const onUpdateInspectionTarget = () => {
        setTargetGrid((grid) => {
            if(grid) {
                (async() => {
                    let result = await getSpecific("IncomingInspectionTargetView");
                    for(let item of result.data) {
                        if(item.lotNo === grid.selectedItems[0].lotNo) {
                            grid.selectedItems[0].inspectionState = item.inspectionState;
                            grid.selectedItems[0].inspectionResultState = item.inspectionResultState;
                            grid.selectedItems[0].inspectionDate = item.inspectionDate;
                            break;
                        }
                    }
                    grid.refresh(false);
                })();
            }
            return grid;
        });
    }

    const onUpdateInspectionItem = () => {
        setTargetItemGrid((grid) => {
            if(grid) {
                (async() => {
                    let result = await getSpecific("SelfInspectionGroupItemView");

                    for(let item of result.data) {
                        if(item.inspectionId === grid.selectedItems[0].inspectionId && 
                           item.inspectionItemId === grid.selectedItems[0].inspectionItemId && 
                           item.inspectionCriterialId === grid.selectedItems[0].inspectionCriterialId
                        ) {
                            grid.selectedItems[0].inspectionState = item.inspectionState;
                            grid.selectedItems[0].inspectionResultState = item.inspectionResultState;
                            grid.selectedItems[0].inspectionDate = item.inspectionDate;
                            break;
                        }
                    }
                    grid.refresh(false);
                })();
            }
            return grid;
        });
    }

    return (
        <div style={{height: height}}>
            <ReflexContainer orientation="horizontal">
                <ReflexElement>
                    <IncomingInspectionTarget 
                        id="IncomingInspectionTarget"
                        masterGridSetting={masterGridSetting}
                        masterApiSetting={masterApiSetting}
                        setTargetGrid={setTargetGrid}
                    />
                </ReflexElement>

                <ReflexSplitter style={{height: layoutConf.reflex.splitterHeight}} />

                <ReflexElement flex={0.5}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={0.3}>
                            {
                                (selectMstGrid)
                                ?
                                <InspectionGroup 
                                    id="InspectionGroupByMaterial" 
                                    selectMstGrid={selectMstGrid}
                                    groupByMaterialGridSetting={groupByMaterialGridSetting}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>

                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />

                        <ReflexElement flex={0.5}>
                            {   
                                (selectGroupByMaterialGrid)
                                ?
                                <InspectionItem
                                    id="InspectionGroupItem" 
                                    selectMstGrid={selectMstGrid}
                                    selectGroupByMaterialGrid={selectGroupByMaterialGrid}
                                    groupItemGridSetting={groupItemGridSetting}
                                    setTargetItemGrid={setTargetItemGrid}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>

                        <ReflexSplitter style={{width: layoutConf.reflex.splitterWidth}} />

                        <ReflexElement flex={0.5}>
                            {
                                (selectGroupItemGrid)
                                ?
                                <InspectionResult 
                                    id="inspectionResult"
                                    selectMstGrid={selectMstGrid}
                                    selectGroupItemGrid={selectGroupItemGrid}
                                    mstApi={mstApi}
                                    inspectionType={defineConf.inspectionType.INPUT_INSPECTION}
                                    onUpdateInspectionTarget={onUpdateInspectionTarget}
                                    onUpdateInspectionItem={onUpdateInspectionItem}
                                />
                                :
                                <></>
                            }
                        </ReflexElement>
                    </ReflexContainer>
                </ReflexElement>
            </ReflexContainer>
        </div>
    );
}

export default IncomingInspection;
