import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import getSchema from "../../lib/api/getSchema";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";
import BasicCrudScreenActionItemLayout from "../screenTypes/layout/BasicCrudScreenActionItemLayout";
import AccessControl from "../functional/AccessControl";
import notificationState from "../../states/notificationState";
import CreateRecordButton from "../input/CreateRecordButton";
import DeleteRecordButton from "../input/DeleteRecordButton";
import MomSysMaterialOptionCreateButton from "../input/MomSysMaterialOptionCreateButton";
import { debounce } from 'lodash';
import UpdateRecordButton from "../input/UpdateRecordButton";

function MomSysMaterialOptionMgmt() {
    const id = 'MomSysMaterialOptionMgmt';

    const [schema, setSchema] = useState();
    const [api, setApi] = useState();
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();

    const [isEmpty, setIsEmpty] = useState();

    const setNotification = useSetRecoilState(notificationState);

    const onLoadData = (data) => {

        setIsEmpty(data.length < 1 ? true : false)

    }

    useEffect(() => {
        let mounted = true;
        async function schemaSetting() {
            let schema = await getSchema("MomSysMaterialOptionMgmt");
            if (mounted) {
                setSchema(schema);
            }
        }

        schemaSetting();
        return (() => {
            mounted = false;
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                }, 10));

                grid.itemsSourceChanged.addHandler(debounce((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0]);
                    } else {
                        setSelectGrid(undefined);
                    };
                }), 10);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [grid]);


    const onApiInitialized = (api) => {
        setApi(api);
    };

    const onInitialized = (grid) => {
        setGrid(grid);
    };

    const notifySuccess = (msg) => {
        setNotification({
            severity: "success",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onSuccess = (msg) => {
        notifySuccess(msg);
    };

    const onFailure = (reason) => {
        notifyFailure(reason.response ? reason.response.data : reason);
    };

    const notifyWarning = (msg) => {
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const onNotifyWarning = (msg) => {
        notifyWarning(msg);
    };

    return <BasicCrudScreenType
        id={id}
        table="MomSysMaterialOptionMgmt"
        view="MomSysVwMaterialOptionMgmt"
        onApiInitialized={onApiInitialized}
        onInitialized={onInitialized}
        noCreate
        noEdit
        noDelete
        noExcel
        onLoadData={onLoadData}
        onAppendButtonInFrontOfSearch={() => (
            <>
                <BasicCrudScreenActionItemLayout>
                    <AccessControl accessTarget={schema && schema.name} accessMethod="POST">
                        <MomSysMaterialOptionCreateButton
                            id={id}
                            schema={schema}
                            refresh={api}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod={schema && (schema.updateWithPatch ? "PATCH" : "PUT")}>
                        <UpdateRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            disabled={isEmpty}
                            onSuccess={onSuccess}
                        />
                    </AccessControl>

                    <AccessControl accessTarget={schema && schema.name} accessMethod="DELETE">
                        <DeleteRecordButton
                            id={id}
                            schema={schema}
                            grid={grid}
                            refresh={api}
                            color="secondary"
                            onSuccess={onSuccess}
                            onFailure={onFailure}
                            onPartialFailure={onNotifyWarning}
                            disabled={isEmpty}
                        />
                    </AccessControl>
                </BasicCrudScreenActionItemLayout>
            </>
        )}
    />
}

export default MomSysMaterialOptionMgmt;

