import { useState } from "react";
import { useTranslation } from "react-i18next";
import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getDefaultLocale } from "../../lib/utils/dateUtil";

function YearMonthDateRangeSelector ( props ) {

    const { t } = useTranslation ();

    const { onChange } = props;
    const date = new Date ();
    const firstMonth = new Date(date.getFullYear(), 0);
    const lastMonth = new Date(date.getFullYear(), 11);

    const [ from, setFrom ] = useState (firstMonth);
    const [ to, setTo ] = useState (lastMonth);

    const onFromChange = ( from ) => {

        setFrom ( from );
        setTo ( ( to ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return from;

            }

            if ( onChange ) {

                onChange ( from, to );

            }

            return to;

        })

    }

    const onToChange = ( to ) => {

        setTo ( to );
        setFrom ( ( from ) => {

            if ( from > to ) {

                if ( onChange ) {

                    onChange ( from, to );

                }

                return to;

            }

            if ( onChange ) {

                onChange ( from, to );

            }

            return from;

        })

    }

    return (

        <div style={{marginTop: "8px", marginLeft: "20px", }}>

            <MuiPickersUtilsProvider   locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        autoOk
                        disableToolbar
                        format={"yyyy/MM"}
                        margin="normal"
                        views={["year","month"]}
                        style={{ marginRight: "16px", marginTop: "5px", width: "140px"}}
                        value={from}
                        onChange={onFromChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

            <span style={{display : "inline-block" ,verticalAlign : "middle", margin : "10px"}}>~</span>
            <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
                <FormControl>
                    <KeyboardDatePicker
                        variant="inline"
                        disableToolbar
                        autoOk
                        format={"yyyy/MM"}
                        views={["year","month"]}
                        margin="normal"
                        style={{marginLeft: "16px", marginTop: "5px", width: "140px"}}
                        value={to}
                        onChange={onToChange}
                    />
                </FormControl>
            </MuiPickersUtilsProvider>

        </div>

    );

}

export default  YearMonthDateRangeSelector;

