/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { FormControlLabel, Checkbox, Snackbar, Divider } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import uiConf from "../../config/uiConf";

import apiClient from "../../lib/common/apiClient";
import checkValidation from "../../lib/utils/checkValidation";
import checkConditionWithMsg from "../../lib/utils/checkConditionWithMsg";

import Dialog from "../dialog/functional/Dialog";
import DialogContent from "../dialog/functional/DialogContent";
import DialogTitle from "../dialog/functional/DialogTitle";
import DialogActionsOkCancel from "../dialog/functional/DialogActionsOkCancel";
import AutoForm from "../form/functional/AutoForm";
import DialogHeaderWithClose from "../dialog/functional/DialogHeaderWithClose";
import { ReflexContainer, ReflexElement, ReflexSplitter } from "react-reflex";
import BasicCrudScreenType from "../screenTypes/functional/BasicCrudScreenType";

import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme) => ({
    keepOpenSwitch: {
        marginLeft: "8px"
    },

    label: {
        fontSize: "smaller",
        color: theme.palette.primary.text
    },
}));

function OriginSeaweedStockSelectCreateDialog (props) {
    const {
        id, schema, process, moveable, resizable, fullWidth, maxWidth, onClose,
        onInitialized, initialData, onSuccess, checkShowCallback,...others
    } = props;

    const  classes = useStyles ();
    const {t} = useTranslation ();

    const [instance, setInstance] = useState ();
    const [interaction, setInteraction] = useState(false);
    const [formData, setFormData] = useState({});
    const [keepOpen, setKeepOpen] = useState(false);
    const [notification, setNotification] = useState({
        open: false,
        severity: "success",
        msg: "",
    });
    
    const [grid, setGrid] = useState();
    const [selectGrid, setSelectGrid] = useState();
    const [, setApi] = useState();
    const [modifySchema, setModifySchema] = useState();

    const checkItemValidation = (schema, formData) => {
        for (const column of schema.columns) {
            let [valid, msg] = checkValidation ("create", column, column.validation, formData[column.name] || "");
            if (!valid) {
                nofifyWarning (msg);
                return false;
            }
        }
        return true;
    }

    useEffect(() => {
        if(schema) {
            let modifiedSchema = cloneDeep(schema);
            for( const column of modifiedSchema.columns) {
                switch(column.name) {
                    case "materialCode":
                        column.hideInForm = false;
                        column.disabled = true;
                        column.readonly = true;
                        column.key = false;
                        break;

                    case "materialName":
                        column.hideInForm = false;
                        column.disabled = true;
                        column.readonly = true;
                        column.key = false;
                        break;

                    case "lotNo":
                        column.hideInForm = false;
                        column.disabled = true;
                        column.readonly = true;
                        column.key = false;
                        break;

                    case "originSeaweedStockDate":
                        column.disabled = false;
                        column.readonly = false;
                        column.key = false;
                        break;
    
                    default :
                        break;
                }
            }

            setModifySchema(modifiedSchema);
        }
    }, [schema]);

    useEffect(() => {
        (async () => {
            if (grid) {
                grid.selectionChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0])
                    } else {
                        setSelectGrid([]);
                    };
                });

                grid.itemsSourceChanged.addHandler((grid, event) => {
                    if (grid.selectedItems && grid.selectedItems[0]) {
                        setSelectGrid(grid.selectedItems[0])
                    } else {
                        setSelectGrid([]);
                    };
                });
            }
        })();
    }, [grid]);

    useEffect(() => {
        if(selectGrid) {
            let newFormData = {
                originSeaweedStockDate: viewDate(),
                jaHo: selectGrid.jaHo,
                inDate: selectGrid.inDate,
                stockQty: selectGrid.stockQty,
                materialCode: selectGrid.materialCode,
                materialName: selectGrid.materialName,
                lotNo: selectGrid.lotNo
            }
            
            setFormData(newFormData);
        }
    }, [selectGrid]);    

    const nofifyWarning = (msg) => {
        console.warn(msg);
        setNotification({
            severity: "warning",
            msg: msg,
            open: true,
        });
    };

    const notifyFailure = (msg) => {
        console.error(msg);
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onDialogInitialized = (instance) => {
        setInstance(instance);
        if (onInitialized) {
            onInitialized(instance);
        }
    };

    const onApiSuccess = (result) => {
        if (onSuccess) {
            onSuccess (t("success.insertSuccess", {table: "MomSysMaterialByCompany"}));
        }

        setFormData ((formData) => {
            let newFormData = {
                ...formData,
            }
            return newFormData;
        });

        setKeepOpen ((keepOpen) => {
            if (!keepOpen) {
                instance.hide();
            }
            return keepOpen;
        });

        if (onSuccess) {
            setTimeout (onSuccess(result.data), 0);
        }
    };

    const onApiFailure = (reason) => {
        notifyFailure (t(reason.response.data));
    };

    const onOk = () => {
        save(modifySchema, formData);
    }

    function save(schema, formData) {
        if (!checkItemValidation(schema, formData)) {
            console.error("Form Validation Failed");
            return;
        }

        if (schema.validation) {
            let [valid, msg] = checkConditionWithMsg(
                formData,
                schema.validation
            );

            if (!valid) {
                nofifyWarning ( t ( "warning.formDataValidationFail", { msg: msg } ) );
                return;
            }
        }

        // build base api url
        let url = "/api/SelectedOriginSeaweedStock";
        let apiData = { ...formData };

        let form = new FormData ();
        let json = {};
        let deleted = {};

        //autoFormDialog columnMap 생성부분 
        if (schema) { 
            let columnMap = {};
            schema.columns.map(column => columnMap[column.name] = column);
            schema.columnMap = columnMap;
        }

        const keys = Object.keys(apiData);

        // 파일정보가 아닌경우 json 추가
        const notFileKeys = keys.filter(key => notFileKey(schema.columnMap, key));
        notFileKeys
            .filter(key => notUndefined(apiData[key], key))
            .forEach(key => json[key] = apiData[key]);

        form.append ("deleted", JSON.stringify(deleted));
        form.append ("json", JSON.stringify(json));
        
        apiClient
            .post(url, form, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((result) => onApiSuccess(result))
            .catch((reason) => onApiFailure(reason));
    }

    const viewDate = (date) => {
        let now = date ? new Date(date) : new Date();
        let fixedDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 15, 0, 0));

        let utcYear = fixedDate.getUTCFullYear();
        let utcMonth = getSingleNumberAddZero(fixedDate.getUTCMonth() + 1);
        let utcDate = getSingleNumberAddZero(fixedDate.getUTCDate() - 1);
        let utcHours = getSingleNumberAddZero(fixedDate.getUTCHours());
        let utcMinutes = getSingleNumberAddZero(fixedDate.getUTCMinutes());
        let utcSeconds = getSingleNumberAddZero(fixedDate.getUTCSeconds());

        return `${utcYear}-${utcMonth}-${utcDate} ${utcHours}:${utcMinutes}:${utcSeconds}`;
    }

    const getSingleNumberAddZero = (value) => {
        return value.toString().padStart(2, "0");
    }


    function notFileKey(columnMap, key) {
        if (columnMap && columnMap[key] && columnMap[key].formItemType !== "file") {
           return key;
        }
    }

    function notUndefined(apiData, key) {
        if(apiData !== undefined) {
            return key;
        }
    }

    const onCancel = () => {
        setFormData ((formData) => {
            let newFormData = {
                ...formData
            }
            return newFormData;
        });
        instance.hide();
    };

    const checkKeepOpen = () => {
        return (
            <>
                <FormControlLabel
                    control={
                        <Checkbox
                            className={classes.keepOpenSwitch}
                            checked={keepOpen}
                            color="primary"
                            onChange={ ()=> setKeepOpen( ( keepOpen ) => ( !keepOpen ) ) }
                            name="checkKeepOpen"
                            size="small"
                        />
                    }
                    label={<span className={classes.label}>{t("term.keepDialogOpen")}</span>}
                />
                <div style={{flexGrow: 1}}></div>
            </>
        )
    }
    
    const onChange = (formData) => {
        if(selectGrid) {
            setFormData({
                originSeaweedStockDate: formData.originSeaweedStockDate,
                jaHo: selectGrid.jaHo,
                inDate: selectGrid.inDate,
                stockQty: selectGrid.stockQty,
                materialCode: selectGrid.materialCode,
                materialName: selectGrid.materialName,
                lotNo: selectGrid.lotNo
            });
            setInteraction (true);
        }
    }

    const onCloseNotification = () => {
        setNotification((notification) => {
            return ({...notification, open: false});
        });
    };

    const onCloseDialog = () => {
        setInteraction (false);
        setFormData ((formData) => {
            let newFormData = {
                ...formData
            }
            return newFormData;
        });
        
        if ( onClose ) {
            onClose ();
        }
    }
    
    return (
        <Dialog
            id={id}
            moveable={moveable ? moveable : uiConf.dialogMoveable}
            resizable={resizable ? resizable : uiConf.dialogResizable}
            fullWidth={fullWidth ? fullWidth : uiConf.dialogFullWidth}
            maxWidth="lg"
            onClose={onCloseDialog}
            onInitialized={onDialogInitialized}
            {...others}
        >
            <DialogHeaderWithClose>
                <DialogTitle>
                    { t ( "dialog.title.OriginSeaweedStock.create" ) }
                </DialogTitle>
            </DialogHeaderWithClose>
        <Divider />

        <DialogContent style={{ padding: "0px", height: "600px" }}>
            <ReflexContainer orientation="vertical">
                <ReflexElement>
                    <BasicCrudScreenType
                        id={id}
                        embededInOtherScreenType
                        headerTitle={t("tableName.OriginSeaweedStockList")}
                        view="OriginSeaweedStockListView"
                        noCreate
                        noEdit
                        noDelete
                        noExcel
                        onInitialized={(grid) => {
                            grid.selectionMode = "Row";
                            setGrid(grid);
                        }}
                        onApiInitialized={(api) => {
                            setApi(api);
                        }}
                    />
                </ReflexElement>

                <ReflexSplitter style={{ width: "1px", color: "lightgray" }} />

                <ReflexElement>
                    <div
                        style={{
                            padding: "16px",
                        }}
                    >
                        <AutoForm
                            id={id + ".create"}
                            schema={modifySchema}
                            mode="edit"
                            onChange={onChange}
                            initialData={formData}
                        />
                    </div>
                </ReflexElement>
            </ReflexContainer>
            <Snackbar
                    open={notification.open && notification.severity !== "success"}
                    autoHideDuration={uiConf.dialogNotifyAutoHideDuration}
                    onClose={onCloseNotification}
            >
                <Alert
                    onClose={onCloseNotification}
                    variant="filled"
                    severity={notification.severity}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>
        </DialogContent>
        <Divider />
        <DialogActionsOkCancel
            firstActionComponent={checkKeepOpen}
            labels={[ t("common.add")]}
            onOk={() => onOk(modifySchema, formData)}
            onCancel={onCancel}
        />
        </Dialog>
    );
}

export default OriginSeaweedStockSelectCreateDialog;
