import DateFnsUtils from '@date-io/date-fns';
import { FormControl } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import getNewDateObj from "../../../lib/common/getNewDateObj";
import { getDefaultDateFormat, getDefaultLocale } from '../../../lib/utils/dateUtil';

function FilterFormItemDate ( props ) {

    const { t } = useTranslation ();
    const { id, className, column, helperText, InputLabelProps, onChange, value, ...others } = props;

    let dateFieldProps = {
        onChange: ( date ) => {

            date?.setHours ( 0, 0, 0, 0 );
            onChange ( date );
            
        },
        ...others
    };

    if ( value !== "" ) {

        dateFieldProps.value = getNewDateObj ( value );

    } else {
            
        dateFieldProps.value = null;
    
    }

    if ( column.filter.InputLabelProps ) {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps, ...column.filter.InputLabelProps };

    } else {

        dateFieldProps.InputLabelProps = { shrink: true, ...InputLabelProps };

    }

    if ( column.filter.placeholder ) {

        dateFieldProps.placeholder = t ( column.filter.placeholder );

    }

    if ( column.filter.variant ) {

        dateFieldProps.variant = column.filter.variant;

    }

    if ( column.filter.inputProps ) {

        dateFieldProps.inputProps = column.filter.inputProps;

    }

    if ( column.filter.InputProps ) {

        dateFieldProps.InputProps = column.filter.InputProps;

    }

    if ( column.filter.style ) {

        dateFieldProps.style = column.filter.style;

    }

    if ( column.filter.disableFuture ) {

        dateFieldProps.disableFuture = column.filter.disableFuture;

    }

    if ( column.filter.disablePast ) {

        dateFieldProps.disablePast = column.filter.disablePast;

    }

    if ( column.minDate ) {

        dateFieldProps.minDate = column.minDate;

    }

    if ( column.maxDate ) {

        dateFieldProps.maxDate = column.maxDate;

    }

    return (
        <MuiPickersUtilsProvider locale={getDefaultLocale(t)} utils={DateFnsUtils}>
            <FormControl className={className} style={{width: "180px"}} {...InputLabelProps} {...others}>
                <KeyboardDatePicker
                    variant="inline"
                    disableToolbar
                    format={getDefaultDateFormat(t, null)}
                    margin="normal"
                    helperText={helperText}
                    {...dateFieldProps}
                    autoOk={true}
                    style={{marginTop: "0px"}}
                />
            </FormControl>
        </MuiPickersUtilsProvider>

    );

}

export default FilterFormItemDate;
