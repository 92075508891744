/* eslint-disable react-hooks/exhaustive-deps */
import DataGridColumn from "./DataGridColumn";
import { FlexGridCellTemplate } from "@grapecity/wijmo.react.grid";
import { useEffect, useState } from "react";

import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import apiClient from "../../../lib/common/apiClient";
import { useRecoilState } from "recoil";
import notificationState from "../../../states/notificationState";
import { useTranslation } from "react-i18next";
// Form Item Type Select에 대응하는 Custom DataGridColumn

function DataGridExcelDownloadColumn(props) {

    const { schema, ...others } = props;

    const [, setNotification] = useRecoilState(notificationState);
    const { t } = useTranslation();

    const [cellTemplate, setCellTemplate] = useState((ctx) => {
        return <></>;
    });

    useEffect(() => {

        setCellTemplate(() => ((ctx) => {

            return (
                <>

                    <span id={"excel-download" + schema?.grid?.path?.replaceAll('/', '-') + schema.name} onClick={() => onClick(ctx)}>

                        <IconButton size="small" sx={{ p: 0, m: 0 }}>

                            <Icon>download</Icon>

                        </IconButton>

                    </span>

                </>
            );

        }));

    }, [schema]);

    const notifyFailure = (msg) => {
        setNotification({
            severity: "error",
            msg: msg,
            open: true,
        });
    };

    const onApiFailure = (reason) => {
        notifyFailure(
            t("success.fileDownloadFailure", { reason })
        );
    };

    const onClick = (ctx) => {
        const prefix = t(schema.grid.fileNamePrefix);
        const value = schema.grid.value;
        const path = schema.grid.path;
        const url = path + ctx.item[value]
        const fileName = schema.grid.fileName;
        let targetFileName = `${prefix}${ctx.item[value]}.xlsx`;
        if (fileName) {
            targetFileName = `${prefix}${ctx.item[fileName]}.xlsx`;
        }

        apiClient
            .get(url, { responseType: "blob" })
            .then((result) => {

                const blob = new Blob([result.data]);
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = targetFileName;
                link.click();
                window.URL.revokeObjectURL(link.href);
            })
            .catch(async (reason) => {
                const blob = reason.response.data
                const msg = await blob.text();
                onApiFailure(msg)
            })

    }

    return (

        <DataGridColumn schema={schema} align="center" {...others} >
            <FlexGridCellTemplate cellType="Cell" template={cellTemplate} />
        </DataGridColumn>

    );

}

export default DataGridExcelDownloadColumn;

